import React from "react";
import theme from "theme";
import { Theme, Box, Text, Image, Link, Strong, LinkBox, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { FaPlay } from "react-icons/fa";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about-us"} />
		<Helmet>
			<title>
				SPhotonix — Revolutionizing Data Storage & Photonics
			</title>
			<meta name={"description"} content={"Discover SPhotonix’s cutting-edge data storage solutions. We specialize in 5D optical storage technology, pushing the boundaries of durability, scalability, and future-proof innovation for enterprise storage systems. Partner with us to bring next-generation data storage to market."} />
			<meta property={"og:title"} content={"SPhotonix — Revolutionizing Data Storage & Photonics"} />
			<meta property={"og:description"} content={"SPhotonix pioneers the future of data storage with breakthrough 5D optical technology. Our innovative solutions offer durable, scalable, and ultra-secure storage for enterprises looking to safeguard their data for decades. Explore how we are revolutionizing the industry."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/sphotonix-new-logo-white.svg?v=2024-09-17T17:56:51.435Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav32w.png?v=2024-09-23T14:28:13.820Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav152w.png?v=2024-09-23T14:28:06.690Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav152w.png?v=2024-09-23T14:28:06.690Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav152w.png?v=2024-09-23T14:28:06.690Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav152w.png?v=2024-09-23T14:28:06.690Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav270w.png?v=2024-09-23T14:27:58.132Z"} />
			<meta name={"msapplication-TileColor"} content={"#ffffff"} />
		</Helmet>
		<Components.Header>
			<Override slot="linkBox14" />
			<Override slot="button" />
			<Override slot="text" />
			<Override slot="text8" />
			<Override slot="text15" />
			<Override slot="text17" />
			<Override slot="image" />
			<Override slot="linkBox8" />
			<Override slot="text11" />
			<Override slot="text10" />
		</Components.Header>
		<Box height="88px" sm-height="52px" />
		<Box
			width="100%"
			height="auto"
			padding-top="120px"
			padding-bottom="64px"
			padding-left="190px"
			padding-right="190px"
			background="#EEEEEE"
			flex-direction="column"
			justify-content="flex-start"
			align-items="flex-start"
			grid-gap="40px"
			display="inline-flex"
			padding="120px 16px 64px 16px"
			sm-padding="60px 16px 24px 16px"
		>
			{"  "}
			<Box
				align-self="stretch"
				height="auto"
				flex-direction="column"
				justify-content="flex-start"
				align-items="center"
				grid-gap="16px"
				display="flex"
			>
				{"    "}
				<Text
					align-self="stretch"
					text-align="center"
					color="#080808"
					font="600 72px/82px Epilogue"
					word-wrap="break-word"
					margin="0px 0px 0px 0px"
					sm-font="600 32px/30px Epilogue"
					sm-text-align="center"
				>
					About Us
				</Text>
				{"    "}
				<Text
					text-align="center"
					color="#212121"
					font="400 21px Epilogue"
					line-height="32px"
					word-wrap="break-word"
					width="747px"
					max-width="100%"
					margin="0px 0px 0px 0px"
					sm-text-align="center"
				>
					At SPhotonix, we are pioneering the future of data storage and optoelectronic technologies. With decades of research in femtosecond laser writing and nanostructuring, we are bringing  solutions to the market that enable new possibilities in data storage, optics, and imaging.
				</Text>
				{"  "}
			</Box>
		</Box>
		<Box
			min-width="100px"
			min-height="100px"
			padding="0px 16px 80px 16px"
			sm-padding="16px 16px 16px 16px"
			background="#000000 url(https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/infographics-bg.webp?v=2024-10-03T21:04:52.510Z) 0% 0%/cover"
		>
			<Box
				min-width="100px"
				min-height="100px"
				max-width="1348px"
				margin="0px auto 0px auto"
				height="700px"
				sm-height="auto"
			>
				<Components.PopupVideo sm-height="180px" height="700px">
					<Override slot="Button Backgound" background="url(https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/DSC00038.jpg?v=2024-09-24T19:44:40.747Z) 50% 50% /cover repeat scroll padding-box" height="100%" filter="brightness(50%)" />
					<Override
						slot="Button Open"
						background="--color-accentRed"
						width="65px"
						position="absolute"
						top="50%"
						left="50%"
						display="flex"
						flex-direction="row"
						flex-wrap="no-wrap"
						align-items="center"
						justify-content="center"
						height="65px"
						border-radius="80px"
						transform="translate(-50%, -50%)"
					/>
					<Override slot="Button Wrapper" position="relative" height="100%" />
					<Override
						slot="Button Text"
						position="absolute"
						left="50%"
						top="50%"
						transform="translateY(40px) translate(-50%, -50%)"
						color="white"
						font="normal normal 400 21px/32px Epilogue"
						sm-display="none"
					>
						Watch Full Video
					</Override>
					<Override slot="Button Open Icon" category="fa" icon={FaPlay} color="#ffffff" />
					<Override slot="Content" padding="0px 0px 0px 0px" />
					<Override slot="Wrapper :open" width="80%" />
					<Override
						slot="Button Close"
						color="#ffffff"
						background="#000000"
						border-radius="24px"
						top="4px"
						right="4px"
					/>
					<Override slot="Overlay" cursor="pointer" />
					<Components.Stream src="3cf8866fd89cb4880c511c2e9e92bddf" />
				</Components.PopupVideo>
			</Box>
		</Box>
		<Box
			width="100%"
			background="linear-gradient(0deg, #EEEEEE 0%, #EEEEEE 100%)"
			flex-direction="column"
			justify-content="flex-start"
			align-items="flex-start"
			grid-gap="40px"
			display="inline-flex"
			md-padding="60px 16px 24px 16px"
			height="auto"
			padding="120px 16px 0px 16px"
		>
			{"  "}
			<Box
				align-self="stretch"
				height="auto"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				grid-gap="16px"
				display="flex"
				flex-wrap="no-wrap"
				margin="0px auto 0px auto"
				max-width="1348px"
				width="100%"
			>
				{"    "}
				<Text
					align-self="stretch"
					text-align="left"
					color="#080808"
					font="600 72px/82px Epilogue"
					word-wrap="break-word"
					margin="0px 0px 0px 0px"
					md-font="600 28px/32px Epilogue"
				>
					Mission{" "}
				</Text>
				{"    "}
				<Text
					text-align="left"
					color="#212121"
					font="400 21px Epilogue"
					line-height="32px"
					word-wrap="break-word"
					width="747px"
					max-width="100%"
					margin="0px 0px 0px 0px"
					md-font="400 17px/26px Epilogue"
				>
					Our mission is to deliver groundbreaking innovation in data storage and ultrafast laser nanostructuring, safeguarding the digital future and pioneering new technologies for a wide range of applications.
				</Text>
				{"  "}
			</Box>
			<Box
				display="flex"
				flex-direction="row"
				flex-wrap="no-wrap"
				md-display="flex"
				md-flex-direction="column"
				md-flex-wrap="no-wrap"
				md-grid-row-gap="40px"
				grid-column-gap="3px"
				margin="0px auto 0px auto"
				max-width="1348px"
				sm-width="100%"
				width="100%"
			>
				<Box
					padding-top="40px"
					padding-bottom="32px"
					padding-left="32px"
					padding-right="32px"
					background="white"
					border="1.50px solid"
					flex-direction="column"
					justify-content="center"
					align-items="flex-start"
					grid-gap="32px"
					display="flex"
					md-padding="0px 0px 0px 0px"
					md-display="flex"
					padding="40px 32px 32px 32px"
					border-width="1.5px"
					border-color="rgba(0, 0, 0, 0)"
					sm-width="100%"
					flex="0 0 auto"
					width="33.300000000000004%"
					md-width="100%"
				>
					{"  "}
					<Box
						align-self="stretch"
						height="auto"
						flex-direction="column"
						justify-content="flex-start"
						align-items="flex-start"
						grid-gap="16px"
						display="flex"
						md-padding="0px 24px 24px 24px"
					>
						{"    "}
						<Text
							align-self="stretch"
							color="#080808"
							font="600 28px Epilogue"
							line-height="32px"
							word-wrap="break-word"
							margin="0px 0px 0px 0px"
						>
							Data Storage Innovation
						</Text>
						{"    "}
						<Text
							align-self="stretch"
							color="#212121"
							font="400 17px Epilogue"
							line-height="26px"
							word-wrap="break-word"
							margin="0px 0px 0px 0px"
							width="100%"
							sm-width="100%"
						>
							5D Glass nano-structuring is a prime candidate to become the mainstream solution for future data storage systems. Our team, are progression pioneering R&D to overcome the remaining challenges in unlocking the full potential of the technology for mainstream deployment.
						</Text>
						{"  "}
					</Box>
					{"  "}
					<Image
						src="https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/DSC00077.JPG?v=2024-09-24T21:07:35.347Z"
						max-width="387px"
						max-height="387px"
						object-fit="cover"
						height="100%"
						width="100%"
						md-order="-1"
						md-max-width="100%"
						md-max-height="290.56px"
						srcSet="https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/DSC00077.JPG?v=2024-09-24T21%3A07%3A35.347Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/DSC00077.JPG?v=2024-09-24T21%3A07%3A35.347Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/DSC00077.JPG?v=2024-09-24T21%3A07%3A35.347Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/DSC00077.JPG?v=2024-09-24T21%3A07%3A35.347Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/DSC00077.JPG?v=2024-09-24T21%3A07%3A35.347Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/DSC00077.JPG?v=2024-09-24T21%3A07%3A35.347Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/DSC00077.JPG?v=2024-09-24T21%3A07%3A35.347Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 1100px) 100vw,100vw"
					/>
				</Box>
				<Box
					background="white"
					border="1.50px solid"
					flex-direction="column"
					justify-content="center"
					align-items="flex-start"
					grid-gap="32px"
					display="flex"
					md-padding="0px 0px 0px 0px"
					md-display="flex"
					padding="40px 32px 32px 32px"
					border-width="1.5px"
					border-color="rgba(0, 0, 0, 0)"
					sm-width="100%"
					flex="1 1 0%"
					width="33.300000000000004%"
					md-width="100%"
				>
					{"  "}
					<Box
						align-self="stretch"
						height="auto"
						flex-direction="column"
						justify-content="flex-start"
						align-items="flex-start"
						grid-gap="16px"
						display="flex"
						md-padding="0px 24px 24px 24px"
					>
						{"    "}
						<Text
							align-self="stretch"
							color="#080808"
							font="600 28px Epilogue"
							line-height="32px"
							word-wrap="break-word"
							margin="0px 0px 0px 0px"
						>
							Optical Device Fabrication
						</Text>
						{"    "}
						<Text
							align-self="stretch"
							color="#212121"
							font="400 17px Epilogue"
							line-height="26px"
							word-wrap="break-word"
							margin="0px 0px 0px 0px"
							width="100%"
						>
							SPhotonix proprietary precision nano-structuring techniques are capable of cost efficient fabrication of unique optical polarisation and birefringence controlled prisms and lenses for a number of optical applications such as microscopy and laser fusion.{" "}
						</Text>
						{"  "}
					</Box>
					{"  "}
					<Image
						src="https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/prism.jpeg?v=2024-09-25T19:47:41.861Z"
						max-width="387px"
						max-height="387px"
						object-fit="cover"
						height="100%"
						width="100%"
						md-order="-1"
						md-max-width="100%"
						md-max-height="290.56px"
						srcSet="https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/prism.jpeg?v=2024-09-25T19%3A47%3A41.861Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/prism.jpeg?v=2024-09-25T19%3A47%3A41.861Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/prism.jpeg?v=2024-09-25T19%3A47%3A41.861Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/prism.jpeg?v=2024-09-25T19%3A47%3A41.861Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/prism.jpeg?v=2024-09-25T19%3A47%3A41.861Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/prism.jpeg?v=2024-09-25T19%3A47%3A41.861Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/prism.jpeg?v=2024-09-25T19%3A47%3A41.861Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 1100px) 100vw,100vw"
					/>
				</Box>
				<Box
					background="white"
					border="1.50px solid"
					flex-direction="column"
					justify-content="center"
					align-items="flex-start"
					grid-gap="32px"
					display="flex"
					md-padding="0px 0px 0px 0px"
					md-display="flex"
					padding="40px 32px 32px 32px"
					border-width="1.5px"
					border-color="rgba(0, 0, 0, 0)"
					width="33.300000000000004%"
					sm-width="100%"
					flex="1 1 0%"
					md-width="100%"
				>
					{"  "}
					<Box
						align-self="stretch"
						height="auto"
						flex-direction="column"
						justify-content="flex-start"
						align-items="flex-start"
						grid-gap="16px"
						display="flex"
						md-padding="0px 24px 24px 24px"
					>
						{"    "}
						<Text
							align-self="stretch"
							color="#080808"
							font="600 28px Epilogue"
							line-height="32px"
							word-wrap="break-word"
							margin="0px 0px 0px 0px"
						>
							Data Archival Solutions
						</Text>
						{"    "}
						<Text
							align-self="stretch"
							color="#212121"
							font="400 17px Epilogue"
							line-height="26px"
							word-wrap="break-word"
							margin="0px 0px 0px 0px"
							width="100%"
						>
							We offer a data write and data read service for anyone needing immediate access to the most durable data storage medium. With tiered volume pricing, and secure logistics options such as Brinks, as well as a secure storage service at a Swiss Vault facility.
						</Text>
						{"  "}
					</Box>
					{"  "}
					<Image
						src="https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/DSC00138.JPG?v=2024-09-24T21:27:49.403Z"
						max-width="387px"
						max-height="387px"
						object-fit="cover"
						height="100%"
						width="100%"
						md-order="-1"
						md-max-width="100%"
						md-max-height="290.56px"
						margin="24px 0px 0px 0px"
						srcSet="https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/DSC00138.JPG?v=2024-09-24T21%3A27%3A49.403Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/DSC00138.JPG?v=2024-09-24T21%3A27%3A49.403Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/DSC00138.JPG?v=2024-09-24T21%3A27%3A49.403Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/DSC00138.JPG?v=2024-09-24T21%3A27%3A49.403Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/DSC00138.JPG?v=2024-09-24T21%3A27%3A49.403Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/DSC00138.JPG?v=2024-09-24T21%3A27%3A49.403Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/DSC00138.JPG?v=2024-09-24T21%3A27%3A49.403Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 1100px) 100vw,100vw"
					/>
				</Box>
			</Box>
		</Box>
		<Box
			width="100%"
			background="linear-gradient(0deg, #EEEEEE 0%, #EEEEEE 100%)"
			flex-direction="column"
			justify-content="flex-start"
			align-items="flex-start"
			grid-gap="40px"
			display="inline-flex"
			padding="120px 16px 64px 16px"
			md-padding="60px 16px 24px 16px"
			height="auto"
			sm-padding="40px 16px 24px 16px"
		>
			{"  "}
			<Box
				height="1px"
				background="#B1B1B1"
				align-items="center"
				flex-direction="column"
				justify-content="center"
				flex-wrap="no-wrap"
				margin="0px 190px 0px 190px"
				md-width="100%"
				md-margin="0px 0px 0px 0px"
				md-background="#080808"
			/>
			<Box
				align-self="stretch"
				height="auto"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				grid-gap="16px"
				display="flex"
				flex-wrap="no-wrap"
				max-width="1348px"
				margin="0px auto 0px auto"
				width="100%"
			>
				{"    "}
				<Text
					align-self="stretch"
					text-align="left"
					color="#080808"
					font="600 72px/82px Epilogue"
					word-wrap="break-word"
					margin="0px 0px 0px 0px"
					md-font="600 28px/32px Epilogue"
				>
					Brief history
				</Text>
				{"    "}
				<Text
					text-align="left"
					color="#212121"
					font="400 21px Epilogue"
					line-height="32px"
					word-wrap="break-word"
					width="747px"
					max-width="100%"
					margin="0px 0px 0px 0px"
					md-font="400 17px/26px Epilogue"
				>
					The journey of SPhotonix spans over two decades of cutting-edge research and innovation. From the discovery of nanostructuring techniques to the formation of a company at the forefront of data storage technology and optoelectronics, our story is rooted in scientific breakthroughs.
				</Text>
				{"  "}
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				flex-direction="row"
				flex-wrap="no-wrap"
				md-display="flex"
				md-flex-direction="column"
				md-flex-wrap="no-wrap"
				md-grid-row-gap="40px"
				max-width="1348px"
				margin="0px auto 0px auto"
				sm-width="100%"
			>
				<Box
					border="1.50px solid"
					flex-direction="column"
					justify-content="flex-start"
					align-items="flex-start"
					grid-gap="32px"
					display="flex"
					md-padding="24px 24px 24px 24px"
					padding="0px 32px 32px 32px"
					border-color="rgba(0, 0, 0, 0)"
					md-border-width="1px"
					md-border-style="solid"
					md-border-radius="24px"
					md-border-color="#080808"
					md-flex-direction="column"
					md-flex-wrap="no-wrap"
					md-grid-row-gap="24px"
					md-align-items="center"
					md-justify-content="space-between"
					md-display="flex"
					width="33.300000000000004%"
					sm-width="100%"
					md-width="100%"
				>
					{"  "}
					<Box
						width="100%"
						align-self="stretch"
						margin="0px 0px 0px 0px"
						text-align="center"
						text-transform="uppercase"
						border-width="1px"
						border-style="solid"
						padding="16px 16px 16px 16px"
						border-radius="80px"
						position="relative"
						md-border-style="none"
						md-padding="0px 0px 0px 0px"
					>
						<Box
							position="absolute"
							top="0px"
							background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/66d0640c995d120018f37959/images/arrow.svg?v=2024-08-31T18:09:11.209Z) 50% 50%/contain no-repeat scroll padding-box"
							height="100%"
							right="0px"
							transform="translateX(100%)"
							width="68px"
							md-display="none"
						/>
						<Text
							color="#080808"
							font="600 21px/21px Epilogue"
							line-height="32px"
							word-wrap="break-word"
							margin="0px 0px 0px 0px"
							md-font="600 17px/20px Epilogue"
						>
							2003
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						md-min-width="fit-content"
						md-min-height="fit-content"
						md-width="100%"
						display="none"
						md-display="block"
					>
						<Box
							height="1px"
							background="#B1B1B1"
							align-items="center"
							flex-direction="column"
							justify-content="center"
							flex-wrap="no-wrap"
							margin="0px 190px 0px 190px"
							md-width="100%"
							md-margin="0px 0px 0px 0px"
							md-background="#080808"
						/>
					</Box>
					<Box
						align-self="stretch"
						height="auto"
						flex-direction="column"
						justify-content="flex-start"
						align-items="flex-start"
						grid-gap="16px"
						display="flex"
					>
						{"        "}
						<Text
							align-self="stretch"
							color="#212121"
							font="400 17px Epilogue"
							line-height="26px"
							word-wrap="break-word"
							margin="0px 0px 0px 0px"
							md-font="400 17px/26px Epilogue"
						>
							Prof. Peter Kazansky, our Chief Science Officer, made a phenomenal discovery enabling{" "}
							<Link href="https://journals.aps.org/prl/abstract/10.1103/PhysRevLett.91.247405" target="_blank">
								irradiation of nano-gratings using ultra short laser light pulses
							</Link>
							, at the University of Southampton  This breakthrough was the foundation of enabling ultrafast precision controled writing of nanostructures.
						</Text>
						{"  "}
					</Box>
					{"  "}
				</Box>
				<Box
					border="1.50px solid"
					flex-direction="column"
					justify-content="flex-start"
					align-items="flex-start"
					grid-gap="32px"
					display="flex"
					md-padding="24px 24px 24px 24px"
					padding="0px 32px 0 32px"
					border-color="rgba(0, 0, 0, 0)"
					md-border-width="1px"
					md-border-style="solid"
					md-border-radius="24px"
					md-border-color="#080808"
					md-flex-direction="column"
					md-flex-wrap="no-wrap"
					md-grid-row-gap="24px"
					md-align-items="center"
					md-justify-content="space-between"
					md-display="flex"
					width="33.300000000000004%"
					sm-width="100%"
					md-width="100%"
				>
					{"  "}
					<Box
						width="100%"
						align-self="stretch"
						margin="0px 0px 0px 0px"
						text-align="center"
						text-transform="uppercase"
						border-width="1px"
						border-style="solid"
						padding="16px 16px 16px 16px"
						border-radius="80px"
						position="relative"
						md-border-style="none"
						md-padding="0px 0px 0px 0px"
					>
						<Box
							position="absolute"
							top="0px"
							background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/66d0640c995d120018f37959/images/arrow.svg?v=2024-08-31T18:09:11.209Z) 50% 50%/contain no-repeat scroll padding-box"
							height="100%"
							right="0px"
							transform="translateX(100%)"
							width="68px"
							md-display="none"
						/>
						<Text
							color="#080808"
							font="600 21px/21px Epilogue"
							line-height="32px"
							word-wrap="break-word"
							margin="0px 0px 0px 0px"
							md-font="600 17px/20px Epilogue"
						>
							2013
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						md-min-width="fit-content"
						md-min-height="fit-content"
						md-width="100%"
						display="none"
						md-display="block"
					>
						<Box
							height="1px"
							background="#B1B1B1"
							align-items="center"
							flex-direction="column"
							justify-content="center"
							flex-wrap="no-wrap"
							margin="0px 190px 0px 190px"
							md-width="100%"
							md-margin="0px 0px 0px 0px"
							md-background="#080808"
						/>
					</Box>
					<Box
						align-self="stretch"
						height="auto"
						flex-direction="column"
						justify-content="flex-start"
						align-items="flex-start"
						grid-gap="16px"
						display="flex"
					>
						{"        "}
						<Text
							align-self="stretch"
							color="#212121"
							font="400 17px Epilogue"
							line-height="26px"
							word-wrap="break-word"
							margin="0px 0px 0px 0px"
							md-font="400 17px/26px Epilogue"
						>
							By 2013, a pivotal conversation between Peter and his son Ilya, an electronic engineer, sparked the idea of using this revolutionary technology for data storage. This collaboration led to a major milestone, with Peter publishing research that demonstrated "
							<Link
								href="https://journals.aps.org/prl/abstract/10.1103/PhysRevLett.112.033901"
								target="_blank"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								Seemingly unlimited lifetime data storage in nanostructured glass
							</Link>
							".
						</Text>
						{"  "}
					</Box>
					{"  "}
				</Box>
				<Box
					border="1.50px solid"
					flex-direction="column"
					justify-content="flex-start"
					align-items="flex-start"
					grid-gap="32px"
					display="flex"
					md-padding="24px 24px 24px 24px"
					padding="0px 32px 0px 32px"
					border-color="rgba(0, 0, 0, 0)"
					md-border-width="1px"
					md-border-style="solid"
					md-border-radius="24px"
					md-border-color="#080808"
					md-flex-direction="column"
					md-flex-wrap="no-wrap"
					md-grid-row-gap="24px"
					md-align-items="center"
					md-justify-content="space-between"
					md-display="flex"
					width="33.300000000000004%"
					sm-width="100%"
					md-width="100%"
				>
					{"  "}
					<Box
						width="100%"
						align-self="stretch"
						margin="0px 0px 0px 0px"
						text-align="center"
						text-transform="uppercase"
						border-width="1px"
						border-style="solid"
						padding="16px 16px 16px 16px"
						border-radius="80px"
						position="relative"
						md-border-style="none"
						md-padding="0px 0px 0px 0px"
					>
						<Text
							color="#080808"
							font="600 21px/21px Epilogue"
							line-height="32px"
							word-wrap="break-word"
							margin="0px 0px 0px 0px"
							md-font="600 17px/20px Epilogue"
						>
							2024
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						md-min-width="fit-content"
						md-min-height="fit-content"
						md-width="100%"
						display="none"
						md-display="block"
					>
						<Box
							height="1px"
							background="#B1B1B1"
							align-items="center"
							flex-direction="column"
							justify-content="center"
							flex-wrap="no-wrap"
							margin="0px 190px 0px 190px"
							md-width="100%"
							md-margin="0px 0px 0px 0px"
							md-background="#080808"
						/>
					</Box>
					<Box
						align-self="stretch"
						height="auto"
						flex-direction="column"
						justify-content="flex-start"
						align-items="flex-start"
						grid-gap="16px"
						display="flex"
					>
						{"        "}
						<Text
							align-self="stretch"
							color="#212121"
							font="400 17px Epilogue"
							line-height="26px"
							word-wrap="break-word"
							margin="0px 0px 0px 0px"
							md-font="400 17px/26px Epilogue"
						>
							Fast forward to 2024, after years of refining the technology, SPhotonix was founded by Peter and Ilya to bring these innovations to the world. SPhotonix now leads the charge in developing commercial applications for patented/pending femtosecond laser writing technology, reshaping the future of data storage, optical devices, and beyond.
						</Text>
						{"  "}
					</Box>
					{"  "}
				</Box>
			</Box>
		</Box>
		<Box
			width="100%"
			background="#000000"
			flex-direction="column"
			justify-content="flex-start"
			align-items="flex-start"
			grid-gap="40px"
			display="flex"
			padding="120px 16px 64px 16px"
			md-padding="60px 16px 60px 16px"
			height="auto"
			grid-row-gap="72px"
			md-display="flex"
			md-grid-row-gap="40px"
		>
			{"  "}
			<Box
				align-self="stretch"
				height="auto"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				grid-gap="16px"
				display="flex"
				flex-wrap="no-wrap"
				max-width="1348px"
				margin="0px auto 0px auto"
				width="100%"
			>
				{"    "}
				<Text
					align-self="stretch"
					text-align="left"
					color="#ffffff"
					font="600 72px/82px Epilogue"
					word-wrap="break-word"
					margin="0px 0px 0px 0px"
					md-font="600 28px/32px Epilogue"
				>
					Recognition
				</Text>
				{"    "}
				<Text
					text-align="left"
					color="#ffffff"
					font="400 21px Epilogue"
					line-height="32px"
					word-wrap="break-word"
					width="747px"
					max-width="100%"
					margin="0px 0px 0px 0px"
					md-font="400 17px/26px Epilogue"
				>
					The founding of SPhotonix is backed through substantial validation of its technology by both scientific and industry recognition. Decades of academic research and development have culminated in a validated set of technology blueprints that SPhotonix continues to develop and bring new innovation to market.{" "}
					<Strong>
						Click images to learn more.
					</Strong>
				</Text>
				{"  "}
			</Box>
			<Box
				display="grid"
				md-flex-direction="column"
				md-flex-wrap="no-wrap"
				md-display="flex"
				md-grid-row-gap="0pxa"
				grid-template-columns="1fr 1fr"
				grid-template-rows="1fr 1fr"
				align-items="center"
				justify-items="stretch"
				height="976px"
				grid-gap="16px"
				md-width="100%"
				md-grid-column-gap="0px"
				md-height="auto"
				max-width="1348px"
				margin="0px auto 0px auto"
				lg-grid-template-columns="1fr"
				lg-height="auto"
				lg-grid-template-rows="1fr 1fr 1fr"
			>
				<LinkBox
					height="100%"
					href="https://www.theverge.com/2019/11/4/20942040/microsoft-project-silica-glass-storage-warner-bros-features-details"
					sm-height="auto"
					grid-column="1 / 2"
					grid-row="1 / 2"
					width="100%"
					lg-grid-column="1/2"
				>
					<Image
						src="https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/Rmwv1wJ.webp?v=2024-09-24T21:31:25.759Z"
						display="block"
						grid-column="1"
						grid-row="1"
						height="100%"
						width="100%"
						object-fit="cover"
						srcSet="https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/Rmwv1wJ.webp?v=2024-09-24T21%3A31%3A25.759Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/Rmwv1wJ.webp?v=2024-09-24T21%3A31%3A25.759Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/Rmwv1wJ.webp?v=2024-09-24T21%3A31%3A25.759Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/Rmwv1wJ.webp?v=2024-09-24T21%3A31%3A25.759Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/Rmwv1wJ.webp?v=2024-09-24T21%3A31%3A25.759Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/Rmwv1wJ.webp?v=2024-09-24T21%3A31%3A25.759Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/Rmwv1wJ.webp?v=2024-09-24T21%3A31%3A25.759Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 1100px) 100vw,100vw"
					/>
				</LinkBox>
				<LinkBox
					grid-row="1 / 3"
					grid-column="2 / 3"
					align-self="auto"
					height="100%"
					href="https://techcrunch.com/2018/02/09/the-special-data-device-spacexs-falcon-heavy-sent-to-orbit-is-just-the-start/"
					target="_blank"
					sm-height="auto"
					width="100%"
					lg-grid-column="1/2"
					lg-grid-row="2/3"
				>
					<Image
						src="https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/ElonGlass%20%281%29.jpeg?v=2024-09-24T21:28:50.949Z"
						display="block"
						grid-column="2"
						height="100%"
						object-fit="cover"
						md-width="100%"
						grid-row="1 / span 2"
						width="100%"
						srcSet="https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/ElonGlass%20%281%29.jpeg?v=2024-09-24T21%3A28%3A50.949Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/ElonGlass%20%281%29.jpeg?v=2024-09-24T21%3A28%3A50.949Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/ElonGlass%20%281%29.jpeg?v=2024-09-24T21%3A28%3A50.949Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/ElonGlass%20%281%29.jpeg?v=2024-09-24T21%3A28%3A50.949Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/ElonGlass%20%281%29.jpeg?v=2024-09-24T21%3A28%3A50.949Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/ElonGlass%20%281%29.jpeg?v=2024-09-24T21%3A28%3A50.949Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/ElonGlass%20%281%29.jpeg?v=2024-09-24T21%3A28%3A50.949Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 1100px) 100vw,100vw"
					/>
				</LinkBox>
				<LinkBox
					grid-row="2 / 3"
					grid-column="1 / 2"
					height="100%"
					href="https://edition.cnn.com/2024/09/20/science/human-genome-crystal-intl-scli/index.html"
					sm-height="auto"
					lg-grid-column="1/2"
					lg-grid-row="3/4"
				>
					<Image
						src="https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/01j8250y13j9cxzdcd113yxgpg.webp?v=2024-09-24T21:32:40.458Z"
						display="block"
						grid-column="1"
						grid-row="2"
						width="100%"
						height="100%"
						object-fit="cover"
						srcSet="https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/01j8250y13j9cxzdcd113yxgpg.webp?v=2024-09-24T21%3A32%3A40.458Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/01j8250y13j9cxzdcd113yxgpg.webp?v=2024-09-24T21%3A32%3A40.458Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/01j8250y13j9cxzdcd113yxgpg.webp?v=2024-09-24T21%3A32%3A40.458Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/01j8250y13j9cxzdcd113yxgpg.webp?v=2024-09-24T21%3A32%3A40.458Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/01j8250y13j9cxzdcd113yxgpg.webp?v=2024-09-24T21%3A32%3A40.458Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/01j8250y13j9cxzdcd113yxgpg.webp?v=2024-09-24T21%3A32%3A40.458Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/01j8250y13j9cxzdcd113yxgpg.webp?v=2024-09-24T21%3A32%3A40.458Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 1100px) 100vw,100vw"
					/>
				</LinkBox>
			</Box>
		</Box>
		<Box
			height="1px"
			background="#B1B1B1"
			align-items="center"
			flex-direction="column"
			justify-content="center"
			flex-wrap="no-wrap"
			margin="0px 190px 0px 190px"
			sm-margin="0px 16px 0px 16px"
		/>
		<Box
			width="100%"
			flex-direction="column"
			justify-content="flex-start"
			align-items="flex-start"
			grid-gap="140px"
			display="inline-flex"
			md-display="flex"
			md-padding="56px 16px 24px 16px"
			md-height="min-content"
			md-grid-row-gap="64px"
			padding="100px 16px 24px 16px"
			background="white"
		>
			{"    "}
			<Box
				align-self="stretch"
				justify-content="flex-start"
				align-items="flex-start"
				grid-gap="80px"
				display="inline-flex"
				md-display="flex"
				md-flex-direction="column"
				md-flex-wrap="no-wrap"
				md-align-items="center"
				md-justify-content="center"
				md-grid-row-gap="24px"
				max-width="1348px"
				margin="0px auto 0px auto"
				width="100%"
			>
				{"    "}
				<Box
					flex="1 1 0"
					flex-direction="column"
					justify-content="flex-start"
					align-items="flex-start"
					grid-gap="28px"
					display="inline-flex"
					md-width="100%"
					md-padding="0px 0px 0px 0px"
					md-height="fit-content"
					md-align-items="center"
					md-display="flex"
					md-flex-direction="column"
					md-justify-content="center"
					md-flex-wrap="no-wrap"
					md-grid-column-gap="0px"
					md-grid-row-gap="8px"
					md-max-width="460px"
				>
					{"      "}
					<Box
						align-self="stretch"
						flex-direction="column"
						justify-content="flex-start"
						align-items="flex-start"
						grid-gap="24px"
						display="flex"
					>
						{"        "}
						<Text
							color="#080808"
							font="600 72px Epilogue"
							line-height="76px"
							word-wrap="break-word"
							margin="0px"
							md-font="600 28px/32px Epilogue"
							md-width="100%"
							text-fill-color="transparent"
							style={{
								"-webkit-text-fill-color": "transparent"
							}}
							background="linear-gradient(96.04deg, #080808 -0.01%, #FC0D1D 42.34%)"
							background-clip="text"
							max-width="100%"
							width="350px"
						>
							Get in touch...
						</Text>
						{"      "}
					</Box>
					{"      "}
					<Text
						align-self="stretch"
						opacity={0.8}
						color="#080808"
						font="400 21px Epilogue"
						line-height="32px"
						word-wrap="break-word"
						margin="0px 0px 0px 0px"
						md-width="100%"
						md-font="400 17px/26px Epilogue"
					>
						<Strong>
							<Link href="mailto:info@sphotonix.com?Web Enquiry: ">
								info@sphotonix.com
							</Link>
						</Strong>
					</Text>
					{"    "}
				</Box>
				{"    "}
				<Components.MakeForm
					md-width="100%"
					max-width="460px"
					sm-max-width="100%"
					action="https://hook.us2.make.com/vmpi9qw5hahp757juwr9sws9xf0n4k2u"
					method="post"
				>
					<Override
						slot="Form"
						flex-direction="column"
						justify-content="flex-start"
						align-items="flex-start"
						grid-gap="16px"
						display="inline-flex"
					/>
					<Override
						slot="Content"
						display="flex"
						flex-direction="column"
						flex-wrap="no-wrap"
						grid-row-gap="16px"
					/>
					<Override slot="Error Text" />
					<Override slot="Success Text" font="--bodyText17Regular" />
					{"      "}
					<Text
						align-self="stretch"
						color="#212121"
						font="400 17px Epilogue"
						line-height="26px"
						word-wrap="break-word"
						margin="0px 0px 0px 0px"
						md-font="400 17px/26px Epilogue"
					>
						Stay ahead with the latest breakthroughs in optical nanotechnology! Discover how SPhotonix is revolutionizing data storage and optical innovation. Join our community for exclusive insights and updates!
					</Text>
					{"      "}
					<Components.MfInput name="name" type="text" width="100%">
						<Override
							slot="Input"
							display="inline-flex"
							placeholder-opacity={0.4}
							placeholder-color="#080808"
							placeholder-font="400 17px Epilogue"
							placeholder-line-height="26px"
							placeholder-word-wrap="break-word"
							width="100%"
							padding-left="24px"
							padding-right="24px"
							padding-top="16px"
							padding-bottom="16px"
							border-radius="8px"
							overflow="hidden"
							justify-content="flex-start"
							align-items="flex-start"
							grid-gap="10px"
							md-width="100%"
							placeholder="Name"
							border-color="#B1B1B1"
							border-width="1px"
							border-style="solid"
							focus-border-color=" #B1B1B1"
							background="none"
						/>
					</Components.MfInput>
					<Components.MfInput name="email" type="email" width="100%">
						<Override
							slot="Input"
							display="inline-flex"
							placeholder-opacity={0.4}
							placeholder-color="#080808"
							placeholder-font="400 17px Epilogue"
							placeholder-line-height="26px"
							placeholder-word-wrap="break-word"
							width="100%"
							padding-left="24px"
							padding-right="24px"
							padding-top="16px"
							padding-bottom="16px"
							border-radius="8px"
							overflow="hidden"
							justify-content="flex-start"
							align-items="flex-start"
							grid-gap="10px"
							md-width="100%"
							placeholder="Email"
							border-color="#B1B1B1"
							border-width="1px"
							border-style="solid"
							focus-border-color=" #B1B1B1"
							background="none"
						/>
					</Components.MfInput>
					{"      "}
					<Button
						width="100%"
						padding-left="24px"
						padding-right="24px"
						padding-top="16px"
						padding-bottom="16px"
						background="#EB1E1E"
						border-radius="8px"
						grid-gap="10px"
						color="white"
						font="600 17px Epilogue"
						line-height="26px"
						word-wrap="break-word"
						text-align="center"
						type="submit"
					>
						Subscribe to Newsletter
					</Button>
					{"    "}
				</Components.MakeForm>
				{"  "}
			</Box>
			{"  "}
			<Box
				align-self="stretch"
				height="51px"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				grid-gap="24px"
				display="flex"
				md-display="flex"
				md-grid-row-gap="32px"
				md-height="auto"
				max-width="1348px"
				margin="0px auto 0px auto"
				width="100%"
			>
				{"    "}
				<Box
					align-self="stretch"
					height="1px"
					background="#B1B1B1"
					md-width="100%"
					md-height="1px"
				/>
				{"    "}
				<Box
					align-self="stretch"
					justify-content="space-between"
					align-items="center"
					grid-gap="32px"
					display="flex"
					md-width="100%"
					md-height="fit-content"
					md-display="flex"
					md-flex-direction="row"
					md-flex-wrap="wrap"
					md-align-content="space-around"
					md-align-items="center"
					md-justify-content="space-between"
					flex-direction="row"
					md-grid-row-gap="16px"
				>
					{"      "}
					<Text
						color="#212121"
						font="400 17px Epilogue"
						line-height="26px"
						word-wrap="break-word"
						margin="0px 0px 0px 0px"
					>
						©2024 SPhotonix. All rights reserved.
					</Text>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						flex-direction="row"
						flex-wrap="no-wrap"
						align-items="center"
						justify-content="center"
						grid-column-gap="32px"
						md-order="-1"
					>
						<Text
							color="#212121"
							font="400 17px Epilogue"
							line-height="26px"
							word-wrap="break-word"
							margin="0px 0px 0px 0px"
						>
							<Link href="/privacy-policy" color="inherit" text-decoration-line="initial">
								Privacy Policy
							</Link>
						</Text>
						<Text
							color="#212121"
							font="400 17px Epilogue"
							line-height="26px"
							word-wrap="break-word"
							margin="0px 0px 0px 0px"
						>
							Cookies
						</Text>
					</Box>
					{"                "}
				</Box>
				{"  "}
			</Box>
		</Box>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"66b79a6ce778710018d27b83"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}\n\n\nbody  {\nzoom: 0.85;\n}\n"}
			</style>
			<script async={true} src={"https://www.googletagmanager.com/gtag/js?id=G-GFK1N3P5RL"} place={"endOfBody"} rawKey={"670146df6aba74b20ebdd83f"} />
			<script place={"endOfBody"} rawKey={"670147228b237590430d8fce"}>
				{"  window.dataLayer = window.dataLayer || [];\n  function gtag(){dataLayer.push(arguments);}\n  gtag('js', new Date());\n\n  gtag('config', 'G-GFK1N3P5RL');"}
			</script>
		</RawHtml>
	</Theme>;
});